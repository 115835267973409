<script>
import $ from "jquery"
import _ from "lodash"
import GlobalVue from '@/libs/Global.vue'
import Gen from '@/libs/Gen'

export default {
	extends: GlobalVue,
  props: {
    type: String,
    value: String,
    label: String,
    uploadType: {default: ""},
    attr:{default:()=>({})},
    param: {default:()=>({thumbnail:false})},
    galleryParams: {default:()=>({})},
    config: Object,
    noHelp: Boolean,
    noLoading: {type:Boolean,default:()=>(true)},
    noPreview: Boolean,
    block: Boolean,
    btnClass: String, 
    readonly: Boolean, 
    isVideo: {type: Boolean, default: () => (false)},
    usePreview: {
      type: Boolean,
      default: false,
    },
    isRounded: {
      type: Boolean,
      default: false,
    },
    cropNotUsingHeight: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      uploading: false,
      error: false,
      inputFile: null,
      imgFile: String,
      fullPreviewOpen: false
    }
  },
  computed:{
    fields(){ return this.$root.fields },
    conf(){ return _.assign(_.clone(this.$root.app.uploader[this.type]),this.config||{}) },
    base(){ return this.$root.apiUrl },
    accept(){
      return "."+this.conf.rule_type.split(",").join(",.")
    },
    uploadText(){
      if(this.value) return "Update"
      return this.uploadType == "gallery" ? "Select" : "Upload"
    },
    random(){ return Math.random() * 10000 },
    widthImage() { return this.conf.rule_size[0] },
    heightImage() { return this.conf.rule_size[1] }
  },
  mounted(){
    this.init()
    this.inputFile = $("<input type='file' accept='"+this.accept+"'>")[0]
    this.inputFile.onclick = ()=>{ this.inputFile.value = '' }
    this.inputFile.onchange = this.onUpload
    global.inputFile = this.inputFile
    
    if(this.$slots.btn) this.$slots.btn[0].elm.onclick = this.uploadClick
  },
  methods: {
    init(){
      if(!this.conf) return
      this.param.type = this.type
      if (!this.uploadType){
        this.param.uploadType = this.conf.img_ratio ? "cropping" : "upload"
      }else{
        this.param.uploadType = _.clone(this.uploadType)
      }
    },
    uploadClick(){
      if(this.uploadType=='gallery') return this.openGallery()
      return this.inputFile.click()
    },
    openGallery(){
      global.FileManager.open(files=>{
        if(files.length){
          var arrayValue = []
          files.forEach(v=>{
            arrayValue.push(v.pathfile)
          })
          this.$emit('input', arrayValue)
        }else{
          this.$emit('input', files.pathfile)
        }
        this.$emit('response', this)
      }, _.assign({type:this.type}, this.galleryParams))
    },
    removeUpload(){
      this.$emit('input', "")
    },
    onUpload(e){
      this.fileData = $(this.inputFile).prop('files')[0]
      this.conf.fileType = this.fileData.type
      this.fileType = this.fileData.name.substr(this.fileData.name.lastIndexOf('.') + 1).toLowerCase()

      if (this.conf.rule_size){
        var reader = new FileReader()
        reader.onload = () => {
          var img = new Image
          img.onload = () => {
            this.img = img
            this.uploadProcess(e)
          }
          img.src = reader.result
        }
        reader.readAsDataURL(this.fileData)
      }else{
        this.uploadProcess(e)
      }
    },
    uploadProcess(){
      // Validation
      this.error = false
      if (this.conf['rule_type'].indexOf(this.fileType) == -1) {
        this.error = 'File type must be ' + this.conf['rule_type'] + ' type.'
      }
      if (this.fileData.size > this.$root.app.uploader['max_image_size']) {
        this.error = 'Max file size is '+this.$root.app.uploader['max_image_size'].bytesToSize()
      }
      if(this.$root.app.uploader[this.type]['max_image_size']){
        if(this.fileData.size > this.$root.app.uploader[this.type]['max_image_size']){
          this.error = 'Max file size is '+this.$root.app.uploader[this.type]['max_image_size'].bytesToSize()
        }
      }
      if (this.conf.rule_size) if(this.img.naturalWidth<this.conf.rule_size[0]||this.img.naturalHeight<this.conf.rule_size[1]){
        this.error = 'Minimum image size is '+this.conf.rule_size[0]+"x"+this.conf.rule_size[1]+'px.'
      }

      if (this.error){
        return this.$swal({
          icon: 'error',
          title: "You can't upload this file",
          text: this.error
        })
      }

      // Automatic upload if not image
      if(("jpg,jpeg,png").indexOf(this.fileType) < 0) this.param.uploadType = "upload"

      // Quick Image Upload Filter
      if(this.param.uploadType != "upload"){
        let reader = new FileReader()
        reader.onload = e=>{
          this.imageFilter(e.target.result, this.fileData.name)
        }
        reader.readAsDataURL(this.fileData)
        return 
      }

      let formData = new FormData()
      formData.append(this.type=="editor" ? 'upload' : 'file', this.fileData)
      this.param.pageType = this.type
      $.each(this.param, (k, v) => {
        formData.append(k, v)
      })
      let query = {
        token: Gen.getCookie("u_auth"),
      }
      this.uploading = true
      $.ajax({
        url: this.apiUrl + "/api/v1/app/ajax/upload?"+Gen.objectToQuery(query),
        headers:Gen.apiHeader(),
        type: "POST",
        data: formData,
        enctype: 'multipart/form-data',
        processData: false, // tell jQuery not to process the data
        contentType: false, // tell jQuery not to set contentType
        xhr: ()=>{
          let xhr = new window.XMLHttpRequest()
          let ajax_progress = $('<p style="margin-bottom:0px;" class="label_progress"></p><div class="progress" style="margin:0px 0px 0px;height:20px;"><div class="progress-bar progress-bar-success progress-bar-striped active" role="progressbar" style="width:3%"></div></div>')
          $(this.$el).find(".ajax_progress").html(ajax_progress)
          //Upload progress
          xhr.upload.addEventListener("progress", evt=>{
            if (evt.lengthComputable) {
              let percentComplete = evt.loaded / evt.total
              let label_process = "File Upload: "+(evt.loaded/1000)+  "Kb / " + (evt.total/1000)+"Kb."
              // Do something with upload progress
              ajax_progress.find('.progress-bar').width((percentComplete*100)+"%")
              $('.label_progress').text(label_process)
              if(percentComplete == 1){
                setTimeout(()=>{ ajax_progress.fadeOut(500) }, 600)
                setTimeout(()=>{ $(this.$el).find(".ajax_progress").html("") }, 1100)
              }
            }
          }, false)
          return xhr
        }
      }).done(resp => {
        this.uploading = false
        if (this.param.uploadType == "upload") {
          this.$emit('input', resp.pathfile)
          this.$emit('resp', resp)
          this.$emit('response', this)
          return
        }
        this.imageFilter(resp.targetfile, resp.filename)
      })
    },

    imageFilter(img, filename){
      let query = {
        token: Gen.getCookie("u_auth"),
      }
      if(this.cropNotUsingHeight) { 
        global.cropNotUsingHeight = true 

        console.log("HEREO")
      }
      global.ImageFilter.open(img, data => {
        var form_data = new FormData()
        let date = new Date()
        form_data.append("file", data)
        form_data.append("path", this.type)
        form_data.append("image_name", filename)
        form_data.append("timestamp", Date.parse(date))
        this.$emit("beforeDone", this)
        // for(let i = 0; i < 2; i++){
        let url = this.apiUrl + "/api/v1/app/ajax/upload_filter?"+Gen.objectToQuery(query)
        $.ajax({
          url: url,
          headers:Gen.apiHeader(),
          type: "POST",
          data: form_data,
          enctype: 'multipart/form-data',
          processData: false,  // tell jQuery not to process the data
          contentType: false,
          success: resp => {
            if(resp.type == 'webp'){
              this.$emit('input', resp.targetfile)
              this.$emit('response', this)
              // upload file ori
              this.imgFile=resp.targetfile
              if($("div").hasClass("avatar_prof")){
                $('.avatar_prof').html('<img src="'+uploader(resp.targetfile)+'" alt="user">')
              }
            }
          }
        })
        // }
      }, this.conf)
    },
  },
  watch:{
    type(){
      setTimeout(()=>{ this.init() },300)
    },
    '$root.app.uploader'(){
      this.init()
    }
  }
}
</script>

<template>
<div v-if="conf">

 <div class="placeholder_gray" :class="{'case-hero-media serv-img-rounder': isRounded, 'wrap_img_section': !isRounded}" :style="!value ? `width: ${widthImage}px; height: ${heightImage}px;` : ``">
    <!-- Delete Button -->
    <a href="javascript:;" class="preview__close bg-danger" @click="removeUpload()" v-if="value"><i class="fas fa-times"></i></a>

    <img :src="uploader(value)" class="img-fluid" :class="{ 'serv-img-rounder cover-img': isRounded }" v-if="value">

    <div class="wrap_overlay" :class="{ 'serv-img-rounder': isRounded }">
      <div class="child">
        <div class="file-uploader-cta" :id="`popover-target-${random}`">
          <b-popover :target="`popover-target-${random}`" triggers="hover" placement="top">
            <template #title>Image Required</template>
            Image Dimension: {{conf.imgsize_recommend}}<br>File Formats: {{conf.rule_type}}<br> Max. File Size: {{(conf.max_image_size||$root.app.uploader["max_image_size"]).bytesToSize()}}
          </b-popover>
          <label class="btn btn-uploader btn-block" @click="uploadClick"><i
              class="icon-cloud-upload"></i><br> {{ `${value ? 'Update' : 'Upload'}` }} </label>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<style scoped>
.cover-img {
  height: 100%;
  object-fit: cover;
}

.placeholder_gray img { height: 100%; object-fit: cover; position: relative; }

.preview__close {
  position: absolute;
  top: -1em;
  right: 0;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 2;
}
</style>